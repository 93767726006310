import { ReactNode, useCallback, useMemo } from "react";
import {
  GridEventListener, GridFilterModel, GridSortModel, GridValueOptionsParams, getGridDateOperators, 
  getGridSingleSelectOperators, getGridStringOperators
} from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { ContactDenorm, ContactOrgDenorm } from "../../../store";
import { Company, ContactCategory } from "../../../store/cabinetApi/generated/crm";
import CRMTable, { CRMGridColDef } from '../CRMTable';
import { Box, SxProps } from "@mui/material";
import colors from "../../../colors";
import { IoEyeOff } from "react-icons/io5";


const contactColumns: CRMGridColDef<ContactDenorm>[] = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    display: "flex",
    flex: 2,
    minWidth: 250,
    filterOperators: getGridStringOperators().filter(op => !['isAnyOf', 'isEmpty', 'isNotEmpty'].includes(op.value)),
    valueGetter: (value, row, column, apiRef) => value || '',
  },
  {
    field: 'primary_email',
    headerName: 'Email',
    display: "flex",
    type: 'string',
    width: 200,
  },
  {
    field: 'company',
    headerName: 'Company',
    type: 'singleSelect',
    display: "flex",
    sortable: false,
    width: 200,
    renderCell: (params) => {
      return params.row.company?.name ?? "";
    },
    filterOperators: getGridSingleSelectOperators().filter(op => op.value !== 'not'),
    // Seems declaring the type here is intended? mui-x maintainer did so in his example here:
    // https://github.com/mui/mui-x/issues/12914#issuecomment-2079571420
    valueGetter: (value: Company | null | undefined, row, column, apiRef) => value?.id,
  },
  {
    field: 'title',
    headerName: 'Title',
    type: 'string',
    display: "flex",
    width: 200,
  },
  {
    field: 'contact_category',
    headerName: 'Category',
    type: 'singleSelect',
    editable: true,
    display: "flex",
    width: 160,
    filterOperators: getGridSingleSelectOperators().filter(op => op.value !== 'not'),
    valueGetter: (value, row, column, apiRef) => value ?? -1,
    chipSelect: true,
  },
  {
    field: 'created_at',
    headerName: 'Created On',
    type: 'dateTime',
    width: 200,
    display: "flex",
    filterOperators: getGridDateOperators(false).filter(op => ['onOrAfter', 'onOrBefore'].includes(op.value)),
    valueGetter: (value, row, column, apiRef) => (
      DateTime.fromISO(value).toJSDate()
    ),
    valueFormatter: (value, row, column, apiRef) => (
      DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_MED)
    ),
  },
];

const ContactTable = ({
  contacts, categories, onNavigateToContact, page, pageSize, rowCount, onPageChange, filter, onSetFilter, orgOptions,
  sort, onSetSort, loading, interactive, paginate, header, footer, defaultHiddenFields, hideTableButtons,
  onUpdateContact, sx
}: {
  contacts: ContactDenorm[];
  orgOptions: ContactOrgDenorm[];
  header?: ReactNode;
  footer?: ReactNode;
  categories: ContactCategory[];
  page: number;
  pageSize: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onNavigateToContact: (id: ContactDenorm['id']) => void;
  filter?: GridFilterModel;
  onSetFilter: (filter: GridFilterModel | undefined) => void;
  sort?: GridSortModel;
  onSetSort: (filter: GridSortModel | undefined) => void;
  loading: boolean;
  interactive: boolean;
  paginate: boolean;
  defaultHiddenFields?: string[];
  hideTableButtons?: boolean;
  onUpdateContact: (
    contact: Partial<Omit<ContactDenorm, 'company'>> & { id: ContactDenorm['id'] }
  ) => Promise<ContactDenorm | undefined>
  sx?: SxProps;
}) => {
  const handleCellClick = useCallback<GridEventListener<'cellClick'>>((params, event, details) => {
    if (params.field === 'name') {
      onNavigateToContact(params.row.id);
    }
  }, [onNavigateToContact]);

  const columns = useMemo(() => contactColumns.map(c => {
    if (c.field === 'contact_category') {
      return {
        ...c,
        valueOptions: categories.map(cat => ({ value: cat.id, label: cat.name, color: cat.color }))
          .concat({ value: -1, label: 'Unassigned', color: colors.black200 }),
      };
    } else if (c.field === 'company') {
      return {
        ...c,
        valueOptions: ({ row }: GridValueOptionsParams<ContactDenorm>) => {
          if (!row?.company) return [];
          return [{ value: row.company.id, label: row.company.name || '' }];
        },
      };
    }
    return c;
  }), [categories]);

  const handleContactRowUpdate = useCallback(async (updatedRow: ContactDenorm, originalRow: ContactDenorm) => {
    const update: ContactDenorm = {
      ...updatedRow,
      contact_category: updatedRow.contact_category === -1 ? null : updatedRow.contact_category,
    };
    const res = await onUpdateContact(update);
    return res || originalRow;
  }, [onUpdateContact]);

  const handleHideContact = useCallback(async (contactId: ContactDenorm['id']) => {
    await onUpdateContact({ id: contactId, hidden_date: DateTime.now().toISO() });
  }, [onUpdateContact]);

  return (
    <>
      {header}
      <Box sx={sx}>
        <CRMTable
          columns={columns}
          rows={contacts}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          onPageChange={onPageChange}
          onCellClick={handleCellClick}
          filter={filter}
          onSetFilter={onSetFilter}
          sort={sort}
          onSetSort={onSetSort}
          loading={loading}
          interactive={interactive}
          paginate={paginate}
          defaultHiddenFields={defaultHiddenFields}
          hideTableButtons={hideTableButtons}
          onRowUpdate={handleContactRowUpdate}
          actions={[{ name: 'Hide', key: 'hide', onClick: (row) => handleHideContact(row.id), icon: IoEyeOff }]}
        />
      </Box>
      {footer}
    </>
  );
};

export default ContactTable;
